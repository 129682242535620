


import Vue from 'vue';
import { mapGetters } from 'vuex';
import { TEvent } from '@/_types/event.type';
import PromoSideBarLeft from '@/_modules/promo/components/side-bar-left/side-bar-left.vue';
import PromoSideBarRight from '@/_modules/promo/components/side-bar-right/side-bar-right.vue';
import ContactInfoInviter from '@/_modules/promo/components/contact-info-inviter/contact-info-inviter.vue';
import { TContact } from '@/_types/contact.type';

enum StatisticsTab {
  PERSONAL = 'personal',
  COMPANY = 'company',
  EVENT = 'event',
}

type TStatisticsData = {
  StatisticsTab: typeof StatisticsTab;
}

type TStatisticsMethods = {
}

type TStatisticsComputed = {
  eventInfo: TEvent;
  myself: TContact;
  eventId: number;
  currentTab: StatisticsTab;
  isEventCreator: boolean;
  canSeeEventStatistics: boolean;
  isCompanyTabVisible: boolean;
}

const Statistics = Vue.extend<TStatisticsData, TStatisticsMethods, TStatisticsComputed>({
  name: 'statistics',
  components: { PromoSideBarLeft, PromoSideBarRight, ContactInfoInviter },
  computed: {
    ...mapGetters({
      eventInfo: '_eventStore/event',
      myself: 'promoPageStore/contact',
    }),
    eventId(): number {
      return Number(this.$route.params.eventId);
    },
    currentTab(): StatisticsTab {
      switch (this.$route.name) {
        case 'result-company':
          return StatisticsTab.COMPANY;

        case 'result-event':
          return StatisticsTab.EVENT;

        case 'result-personal':
        default:
          return StatisticsTab.PERSONAL;
      }
    },
    isEventCreator(): boolean {
      if (!this.eventInfo || !this.myself) {
        return false;
      }
      return this.eventInfo.creator_user_id === this.myself.user_id;
    },
    canSeeEventStatistics(): boolean {
      return this.isEventCreator;
    },
    isCompanyTabVisible(): boolean {
      return this.myself && !!this.myself.promopage_external_id;
    },
  },
  created(): void {
    this.$store.dispatch('promoPageStore/getContact', { eventId: this.eventId});
  },
  mounted(): void {
    if (!this.isEventCreator) {
      this.$store.dispatch('_eventStore/getEvent', this.eventId);
    }
  },
  data(): TStatisticsData {
    return {
      StatisticsTab: StatisticsTab,
    };
  },
  methods: {

  }
});

export default Statistics;
